import { useState } from "react";

import s from "./InviteForm.module.scss";
import InputWidget from "../../components/input-widget/InputWidget";
import AppButton from "../../components/app-button/AppButton";
import { backendCreateInvitationSendLink } from "../../backend/invitations";
import "react-toastify/dist/ReactToastify.css";
import { useWalletService } from "../../helpers/WalletService";
import { WalletServiceResponse } from "../../models/AppModels";

interface InvitationModel {
	name?: string;
	email?: string;
	website?: string;
	privilegeLevel?: string;
	industryName?: string;
}

export default function InviteForm() {
	const DUMMY_INVITATION_MODEL = {
		name: "LucasArts",
		email: "luc@asa.rts",
		website: "https://lucas.arts/website",
		privilegeLevel: "il",
		industryName: "Luxury Pencil Sharpeners"
	};

	const [invitationModel, setInvitationModel] = useState<InvitationModel>(
		DUMMY_INVITATION_MODEL
	);

	const { walletKey } = useWalletService() as WalletServiceResponse;

	// Methods

	const updateModel = (key: string, value: string) => {
		setInvitationModel({ ...invitationModel, [key]: value });
	};

	const invite = () => {
		console.log("Starting invitation flow...");
		backendCreateInvitationSendLink(walletKey?.toString(), invitationModel).then(
			(r) => {
				window.alert("Invitation send result: " + JSON.stringify(r, null, 2));
			}
		);
		// TODO: based on backendInvite response show something to the SA/IL
	};

	return (
		<div className={s.root}>
			<h1>Connected wallet: {walletKey?.toString()} </h1>
			<div className={s.inputContainer}>
				<InputWidget
					value={invitationModel.name || ""}
					isMandatory
					type="text"
					title="Company Name"
					placeholder="Company name"
					onChange={(v: any) => updateModel("company_name", v)}
				/>
			</div>

			<div className={s.inputContainer}>
				<InputWidget
					value={invitationModel.email || ""}
					isMandatory
					type="text"
					title="Company Email"
					placeholder="my@comp.any"
					onChange={(v: any) => updateModel("company_email", v)}
				/>
			</div>

			<div className={s.inputContainer}>
				<InputWidget
					value={invitationModel.website || ""}
					isMandatory
					type="text"
					title="Company Website"
					placeholder="https://comp.any/website"
					onChange={(v: any) => updateModel("company_website", v)}
				/>
			</div>

			<div className={s.inputContainer}>
				<InputWidget
					value={invitationModel.privilegeLevel || ""}
					isMandatory
					type="text"
					title="Privilege level"
					placeholder="Industry Member / Industry Lead"
					onChange={(v: any) => updateModel("privilege_level", v)}
				/>
			</div>

			<div className={s.inputContainer}>
				<InputWidget
					value={invitationModel.industryName || ""}
					isMandatory
					type="text"
					title="Industry Name"
					placeholder="Industry name"
					onChange={(v: any) => updateModel("industryName", v)}
				/>
			</div>

			<AppButton
				text="Invite"
				className={s.registerButton}
				onClick={() => invite()}
			/>
		</div>
	);
}

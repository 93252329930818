import React from "react";
//import ShoppingBagIcon from "../../../icons/ShoppingBagIcon";

import s from "./MembersList.module.scss";
import LaunchIcon from "../../../icons/LaunchIcon";
import { IndustryModel } from "../../../../models/AppModels";
import Notification from "../../../notification/Notification";

export default function MembersList({
	industries = [],
}: {
	industries: IndustryModel[];
}) {
	return (
		<div className={s.root}>
			{industries.map(({ name }: { name: string }, index: number) => (
				<div key={index}>
					{/* <Notification hasNotification={true} /> */}

					<div className={s.name}>{name}</div>
					<div className={s.flex}>
						{/* <ShoppingBagIcon /> */}
						{/* <div className={s.note}>Lorem ipsum dolom</div> */}
					</div>

					{/* <div className={s.launchIcon}>
						<LaunchIcon />
					</div> */}
				</div>
			))}
		</div>
	);
}

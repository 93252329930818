import React from "react";

export default function UserProfileIcon({ width = 55, height = 55 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 55 55"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.5013 27.5C24.9805 27.5 22.8225 26.6024 21.0273 24.8073C19.2322 23.0122 18.3346 20.8542 18.3346 18.3333C18.3346 15.8125 19.2322 13.6545 21.0273 11.8594C22.8225 10.0642 24.9805 9.16667 27.5013 9.16667C30.0221 9.16667 32.1801 10.0642 33.9753 11.8594C35.7704 13.6545 36.668 15.8125 36.668 18.3333C36.668 20.8542 35.7704 23.0122 33.9753 24.8073C32.1801 26.6024 30.0221 27.5 27.5013 27.5ZM9.16797 45.8333V39.4167C9.16797 38.1181 9.50217 36.9245 10.1706 35.8359C10.839 34.7474 11.727 33.9167 12.8346 33.3438C15.2027 32.1597 17.6089 31.2717 20.0534 30.6797C22.4978 30.0877 24.9805 29.7917 27.5013 29.7917C30.0221 29.7917 32.5048 30.0877 34.9492 30.6797C37.3937 31.2717 39.7999 32.1597 42.168 33.3438C43.2756 33.9167 44.1636 34.7474 44.832 35.8359C45.5004 36.9245 45.8346 38.1181 45.8346 39.4167V45.8333H9.16797ZM13.7513 41.25H41.2513V39.4167C41.2513 38.9965 41.1463 38.6146 40.9362 38.2708C40.7261 37.9271 40.4492 37.6597 40.1055 37.4688C38.043 36.4375 35.9614 35.6641 33.8607 35.1484C31.76 34.6328 29.6402 34.375 27.5013 34.375C25.3624 34.375 23.2426 34.6328 21.1419 35.1484C19.0412 35.6641 16.9596 36.4375 14.8971 37.4688C14.5534 37.6597 14.2765 37.9271 14.0664 38.2708C13.8563 38.6146 13.7513 38.9965 13.7513 39.4167V41.25ZM27.5013 22.9167C28.7617 22.9167 29.8407 22.4679 30.7383 21.5703C31.6359 20.6727 32.0846 19.5938 32.0846 18.3333C32.0846 17.0729 31.6359 15.9939 30.7383 15.0964C29.8407 14.1988 28.7617 13.75 27.5013 13.75C26.2409 13.75 25.1619 14.1988 24.2643 15.0964C23.3668 15.9939 22.918 17.0729 22.918 18.3333C22.918 19.5938 23.3668 20.6727 24.2643 21.5703C25.1619 22.4679 26.2409 22.9167 27.5013 22.9167Z"
				fill="#F5F6FC"
			/>
		</svg>
	);
}

import { getNodeApi, ORGANIZATION, PLUGIN_NAME, pub, SCOPE, useBackend } from "./common";

const CHECK_ROLE = "checkRole";

export const backendCheckMembershipNFT = async (walletKey: string, signMessage: (s: string) => string) => {
    if (!useBackend)
        return new Promise((resolve) => {
            resolve({ res: "ok", data: '{"role":"csia_superadmin"}' });
        });

    const nodeApi = getNodeApi();
    const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

    let toSign = { wallet_key: walletKey.toString(), ts: Date.now() };
    const signature = await signMessage(JSON.stringify(toSign));
    const args = { ...toSign, "phantom_signature": signature };
    return await nodeApi.pluginCall(
        session,
        PLUGIN_NAME,
        CHECK_ROLE,
        args,
        null
    );
}
import React from "react";

export default function DirectoryIcon({ width = 45, height = 45 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2467_8722)">
        <path
          d="M30 20.625C33.1125 20.625 35.6063 18.1125 35.6063 15C35.6063 11.8875 33.1125 9.375 30 9.375C26.8875 9.375 24.375 11.8875 24.375 15C24.375 18.1125 26.8875 20.625 30 20.625ZM15 20.625C18.1125 20.625 20.6062 18.1125 20.6062 15C20.6062 11.8875 18.1125 9.375 15 9.375C11.8875 9.375 9.375 11.8875 9.375 15C9.375 18.1125 11.8875 20.625 15 20.625ZM15 24.375C10.6313 24.375 1.875 26.5688 1.875 30.9375V35.625H28.125V30.9375C28.125 26.5688 19.3687 24.375 15 24.375ZM30 24.375C29.4562 24.375 28.8375 24.4125 28.1812 24.4688C30.3562 26.0438 31.875 28.1625 31.875 30.9375V35.625H43.125V30.9375C43.125 26.5688 34.3688 24.375 30 24.375Z"
          fill="#BCBFCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_2467_8722">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import React from "react";

export default function AddIcon() {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Plus">
				<path
					id="Icon"
					d="M12 5.5V19.5M5 12.5H19"
					stroke="#F5F6FC"
					strokeWidth="2.08333"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}

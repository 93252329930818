

export const dbIndustryToIndustrySelector = (dbIndustries) => {
    let industrySelectorIndustries = [];
    for (let i = 0; i < dbIndustries.length; i++) {
        industrySelectorIndustries.push({ value: dbIndustries[i].id, label: dbIndustries[i].name })
    }
    return industrySelectorIndustries;
}

export const dbApplicationToFrontendApplication = (dbApplications) => {
    return dbApplications.map(dbApplication => {
        return {
            id: dbApplication.id,
            ts: dbApplication.ts,
            isColdSignUp: dbApplication.isColdSignUp,
            emailVerified: dbApplication.emailVerified,
            status: dbApplication.status,
            industryId: dbApplication.industryId,
            name: dbApplication.name,
            email: dbApplication.email,
            walletKey: dbApplication.walletKey,
            website: dbApplication.website

        }
    });
}
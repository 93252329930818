import React from "react";

export default function VerifyIcon({ width = 34, height = 29 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 34 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
				<path
					d="M25.7121 1.78137H7.91329C4.16854 1.78137 1.13281 4.4557 1.13281 7.75465V21.1945C1.13281 24.4935 4.16854 27.1678 7.91329 27.1678H25.7121C29.4568 27.1678 32.4925 24.4935 32.4925 21.1945V7.75465C32.4925 4.4557 29.4568 1.78137 25.7121 1.78137Z"
					stroke="#9DA2B3"
					strokeWidth="1.99109"
				/>
				<path
					d="M1.21289 7.8891L13.4262 14.0565C14.4495 14.5803 15.6118 14.8562 16.7953 14.8562C17.9787 14.8562 19.1411 14.5803 20.1643 14.0565L32.4116 7.8891"
					stroke="#9DA2B3"
					strokeWidth="1.99109"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
		</svg>
	);
}

import { getNodeApi, PLUGIN_NAME, pub } from "./common";

const NEW_INVITATION_REQUEST = "invite";
const FETCH_INVITATION_REQUEST = "fetch_invitation";

const ORGANIZATION = "csia";
const SCOPE = "csia_sa";

export const backendCreateInvitationSendLink = async (invitor, invitationModel) => {
    const nodeApi = getNodeApi();
    const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

    // TODO: industryId lookup based on industryName or whatever
    invitationModel.industryId = 1;
    // TODO: should we add a signature with the phantom private key?
    const args = { invitor_pub_key: invitor, invitation: invitationModel }
    const result = await nodeApi.pluginCall(session, PLUGIN_NAME, NEW_INVITATION_REQUEST, args, null);
    return result;
}

export const backendFetchInvitation = async (token) => {
    const nodeApi = getNodeApi();
    const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

    const args = { token: token };
    return  await nodeApi.pluginCall(session, PLUGIN_NAME, FETCH_INVITATION_REQUEST, args, null);
}

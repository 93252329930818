import React from "react";

import { Card, CardTitle, CardBody } from "reactstrap";

import s from "./ProfileIndustriesCard.module.scss";

import MembersList from "./members-list/MembersList";
import SearchIcon from "../../icons/SearchIcon";
import TabsWidget from "../../tabs-widget/TabsWidget";
import { IndustryModel } from "../../../models/AppModels";

export default function ProfileIndustriesCard({
	industries = [],
}: {
	industries: IndustryModel[];
}) {
	const tabs = [
		{ name: "members", title: "MEMBERS" },
		{ name: "apps", title: "APPLICATIONS" },
	];

	const [activeTab, setActiveTab] = React.useState(tabs[0].name);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	const notSuperadmin = (ind: IndustryModel[]) => {
		const x = ind && ind.length > 0 && ind[0].name.toString() === "0";
		return !x;
	}

	return (
		(
			notSuperadmin(industries)
				? <Card className={s.root}>
					<CardBody>

						<CardTitle className={s.title}>Industries</CardTitle>

						{/* <div className={s.searchIcon}>
					<SearchIcon />
				</div> */}

						<MembersList industries={industries} />

						{/* <TabsWidget
					tabs={tabs}
					activeTab={activeTab}
					handleTabChange={handleTabChange}
				> */}
						{/* {activeTab === tabs[0].name ? ( */}
						{/* ) : null} */}
						{/* {activeTab === tabs[1].name ? <div>APPLICATIONS</div> : null} */}
						{/* </TabsWidget> */}
					</CardBody>
				</Card>
				: null)
	);
}

import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useLocation, useNavigate, NavLink } from "react-router-dom";

import s from "./SidebarLink.module.scss";
import AuthService from "../../../helpers/AuthService";
import Notification from "../../notification/Notification";

const SidebarLink = ({
	url,
	name,
	icon: IconTag,
	onlyIcon = false,
	hasNotification,
}) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		const path = location.pathname;

		const isCurrentUrl =
			url === "/"
				? path.indexOf("profile") >= 0 || path === url
				: path.indexOf(url) >= 0;

		setIsActive(!!isCurrentUrl);
	}, [location, url]);

	// ------------------------------------- METHODS -------------------------------------
	const onLinkClicked = (e) => {
		if (name === "Logout") {
			AuthService.logout()
				.then(() => navigate("login"));
		}
	};

	return (
		<NavLink
			to={url}
			className={cx(s.root, {
				[s.settings]: name === "Settings",
				[s.profile]: name === "Profile",
			})}
			children={() => (
				<div
					onClick={onLinkClicked}
					className={cx(s.link, {
						[s.activeLink]: isActive,
					})}
				>
					{IconTag ? (
						<IconTag className={s.icon} color={isActive ? "#F5F6FC" : ""} />
					) : null}
					{onlyIcon ? null : <p className={s.name}>{name}</p>}
					<Notification
						hasNotification={hasNotification}
						className={s.notification}
					/>
				</div>
			)}
		/>
	);
};

export default SidebarLink;

import React from "react";

import s from "./WelcomeComponent.module.scss";
import { UserModel } from "../../../models/AppModels";

export default function WelcomeComponent({ user }: { user: UserModel }) {
	return (
		<div className={s.root}>
			<p className={s.welcomeText}>Welcome,</p>
			<div className={s.details}>
				<img className={s.logo} src={user.logo} alt="..." />
				<div>
					<div className={s.name}>{user.name}</div>
					<div className={s.company}>{user.company}</div>
				</div>
			</div>
		</div>
	);
}

import React, { useEffect, useState } from "react";

import s from "./ProfilePage.module.scss";
import ProfileDetailsCard from "../../components/profile/profile-details-card/ProfileDetailsCard";
import ProfileIndustriesCard from "../../components/profile/Industries-card/ProfileIndustriesCard";
import NewMemberCard from "../../components/profile/new-member-card/NewMemberCard";
import AuthService from "../../helpers/AuthService";
import { backendFetchProfile } from "../../backend/sessiondata";
import { IndustryModel, UserModel } from "../../models/AppModels";
import { setuid } from "process";
import { backendFetchIndustries } from "../../backend/industries";
import { dbIndustryToIndustrySelector } from "../../backend/helpers";
import { useNavigate } from "react-router";
//import DVTCard from "../../components/profile/dvt-card/DVTCard";
//import ContactsCard from "../../components/profile/directories-card/DirectoriesCard";
//import CertificationsCard from "../../components/profile/certifications-card/CertificationsCard";
//import NewMemberCard from "../../components/profile/new-member-card/NewMemberCard";

export default function ProfilePage() {
	// TODO:
	// const _user = {
	// 	logo: "derek",
	// 	name: "Authorized Rolex Reseller 06",
	// 	description: "Member since 2023",
	// 	company: {
	// 		name: "Authorized Rolex Reseller 04",
	// 		emails: ["hello@reseller.com", "reseller04.com"],
	// 	},
	// 	bussiness_documents: ["1", "2"],
	// 	industry: {
	// 		owner: "Rolex SA",
	// 		name: "Fine Art and Collectibles",
	// 	},
	// 	privilege_level: "member",
	// };

	const navigate = useNavigate();
	const [user, setUser] = useState<UserModel>({});
	const [indstries, setIndustries] = useState<IndustryModel[]>([]);
	const loggedInSession = AuthService.getCurrentUser();
	const [allowedRender, setAllowedRender] = useState(false);

	useEffect(() => {
		backendFetchProfile(loggedInSession)
			.then(r => {
				const userAccounts = JSON.parse(r.data);
				let _user = {
					logo: "placeholder",
					name: userAccounts[0].name,
					description: "Member since " + timestampToYear(userAccounts[0].ts),
				};
				setUser(_user);
				let industryIds = [] as Number[];
				for (let i = 0; i < userAccounts.length; i++) {
					industryIds.push(Number(userAccounts[i].industry_id));
				}
				backendFetchIndustries()
					.then(r => dbIndustryToIndustrySelector(r))
					.then(r => {
						let backendIndustries = r.filter(i => industryIds.includes(Number(i.value)));
						let _industries = [];
						for (let i = 0; i < backendIndustries.length; i++) {
							_industries.push({ name: backendIndustries[i].label, owner: "abc" });
						}
						setIndustries(_industries);
					});
				setAllowedRender(true);
			});
	}, []);

	const timestampToYear = (ts: string) => {
		return new Date(Number(ts)).getFullYear();
	}

	// TODO: check whether superadmin
	const isSuperadmin = false; //AuthService.getCurrentUser().endsWith("csia_role_superadmin");

	return (
		allowedRender ?
			(<div className={s.root}>
				<div className={s.headerBg}></div>

				<ProfileDetailsCard user={user} />

				<div className={s.content}>
					<div>
						<ProfileIndustriesCard industries={indstries} />
						{/* <ContactsCard /> */}
					</div>
					{/* <div> */}
					{/* {isSuperadmin && <NewMemberCard user={user} />} */}
					{/* <DVTCard />
					<CertificationsCard /> */}
					{/* </div> */}
				</div>
			</div>)
			: null
	);
}

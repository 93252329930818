import React from "react";

import cx from "classnames";

import s from "./TabsWidget.module.scss";

export default function TabsWidget({
	tabs,
	activeTab,
	handleTabChange,
	children,
}) {
	return (
		<>
			<ul className={s.root}>
				{tabs.map(({ name, title }, index) => (
					<li
						key={index}
						className={cx({ [s.active]: activeTab === name })}
						onClick={() => handleTabChange(name)}
					>
						{title}
					</li>
				))}
			</ul>

			<>{children}</>
		</>
	);
}

import React from "react";

import s from "./SelectedDocuments.module.scss";

import RemoveIcon from "../icons/RemoveIcon";
import { DocumentModel } from "../../../models/AppModels";

export type SelectedDocumentsProps = {
	documents: DocumentModel[];
	onRemoveFile?: (doc: DocumentModel) => void;
};

const COLORS = ["#386D90", "#5C7D92"];

export default function SelectedDocuments({
	documents = [],
	onRemoveFile,
}: SelectedDocumentsProps) {
	return (
		<div className={s.root}>
			{(documents || []).map((doc, index) => (
				<div
					key={index}
					className={s.doc}
					style={{ backgroundColor: COLORS[index % COLORS.length] }}
				>
					{onRemoveFile && !doc.isReadonly ? (
						<div onClick={() => onRemoveFile(doc)} className={s.removeIcon}>
							<RemoveIcon />
						</div>
					) : null}
				</div>
			))}
		</div>
	);
}

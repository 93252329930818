import React from "react";

import cx from "classnames";

import s from "./DashboardPage.module.scss";
import WelcomeCard from "../../components/dashboard/welcome-component/WelcomeComponent";
import IndustryLogo from "../../assets/images/dashboard/crown.svg";
import AppButton from "../../components/app-button/AppButton";
import DashboardHeader from "../../components/dashboard/dashboard-header/DashboardHeader";
import TabsWidget from "../../components/tabs-widget/TabsWidget";
import DashboardHome from "../../components/dashboard/dashboard-home/DashboardHome";
import { pluralize } from "../../helpers/Utils";

export default function DashboardPage() {
	// TODO:
	const user = {
		logo: IndustryLogo,
		name: "Rolex SA",
		company: "Rolex Industry Admin",
		industry: {
			name: "Authorized Rolex Resellers Member Hub",
			description: "Description text",
		},
	};

	const membersCount = 213;

	const tabs = [
		{ name: "home", title: "HOME" },
		{
			name: "directory",
			title: `DIRECTORY (${pluralize("member", membersCount)})`,
		},
	];

	const [activeTab, setActiveTab] = React.useState(tabs[0].name);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	return (
		<div className={s.root}>
			<DashboardHeader data={user.industry} />

			<div className={s.content}>
				<div className={s.top}>
					<WelcomeCard user={user} />
					<AppButton
						text="Member Chat"
						className={cx(s.button, "btn-primary")}
					/>
				</div>

				<TabsWidget
					tabs={tabs}
					activeTab={activeTab}
					handleTabChange={handleTabChange}
				>
					{activeTab === tabs[0].name ? <DashboardHome /> : null}
					{activeTab === tabs[1].name ? <div>Dashboard directory</div> : null}
				</TabsWidget>
			</div>
		</div>
	);
}

import React from "react";

import s from "./ProfileDetailsCard.module.scss";
import { UserModel } from "../../../models/AppModels";

export default function ProfileDetailsCard({ user }: { user: UserModel }) {
	return (
		<div className={s.root}>
			{user.logo ? <div className={s.avatar}></div> : null}

			<div className={s.info}>
				<div className={s.name}>{user.name}</div>
				{/* {user.company ? <div className={s.company}>{user.company?.name}</div> : null} */}
				{user.description ? (
					<div className={s.company}>{user.description}</div>
				) : null}
			</div>
		</div>
	);
}

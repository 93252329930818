import React from "react";

import s from "./ImageGradientWidget.module.scss";

interface ImageProps {
	children: any;
}

export default function ImageGradientWidget({ children }: ImageProps) {
	return (
		<div className={s.root}>
			<>{children}</>
		</div>
	);
}

import React from "react";
import { BrowserRouter } from "react-router-dom";

import "./assets/styles/app.scss";

import { WalletServiceProvider } from "./helpers/WalletService";
import StoreProvider from "./_redux/store";
import store from "./_redux/store";
import CsiaApp from "./CsiaApp";

class App extends React.PureComponent {
	render() {
		return (
			<StoreProvider store={store}>
				<BrowserRouter>
					<WalletServiceProvider>
						<CsiaApp />
					</WalletServiceProvider>
				</BrowserRouter>
			</StoreProvider>
		);
	}
}

export default App;

const _downloadFile = ({ url, filename }) => {
	let hlink = document.createElement("a");
	hlink.href = url;
	hlink.download = filename;
	hlink.click();
};

export const viewDocument = (f) => {
	const url = f.data || f.file || f.content;

	// View uploaded files on Create Instrument page
	if (f.type === "text/csv") {
		const blob = new Blob([atob(f.data)], {
			type: "data:application/octet-stream;base64",
		});

		return _downloadFile({
			url: URL.createObjectURL(blob),
			filename: f.filename,
		});
	}

	fetch(url)
		.then((response) => response.blob())
		.then((blob) => window.open(URL.createObjectURL(blob), "_blank"));
};

export const isPrefilledMagicLink = (location, params) => {
	const isPrefilledMagicLink =
		params.token && location.pathname.startsWith("/register/magic/");
	return isPrefilledMagicLink;
};

export const formatAmount = (amount, precision = 2, limitExceeded) => {
	if (!amount) amount = 0;

	const result =
		(1 * amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
		(1 * amount - Math.floor(1 * amount)).toFixed(precision).substr(1);

	return "$" + (limitExceeded && result > 0 ? `${result}` : result);
};

export const pluralize = (text, itemsCount, skipNumber = false) => {
	return skipNumber
		? `${itemsCount * 1 === 1 ? text : text + "s"}`
		: `${itemsCount} ${itemsCount * 1 === 1 ? text : text + "s"}`;
};

export const hasItems = (list) => {
	return list && list.length > 0;
};

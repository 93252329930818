import React from "react";

import Icon from "../../../assets/images/sidebar/industryicon_fineart.png";

export default function IndustryIcon() {
	return (
		<img
			src={Icon}
			alt="..."
			style={{ width: "56px", height: "57px", borderRadius: "10px" }}
		/>
	);
}

import { getNodeApi, ORGANIZATION, PLUGIN_NAME, pub, SCOPE, useBackend } from "./common";

const GET_DASHBOARD_DATA = "getDashboardData";

export const backendFetchProfile = async (sessionV0: string) => {
    if (!useBackend) { // TODO: remove once we have customer environment
        return null; // TODO: return mock data
    }

    const nodeApi = getNodeApi();
	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

    const args = { sessionV0 };
	return await nodeApi.pluginCall(
		session,
		PLUGIN_NAME,
		GET_DASHBOARD_DATA,
		args,
		null);
}
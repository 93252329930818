import React, { useEffect, useState } from "react";
import Select from "react-select";

import s from "./IndustrySelector.module.scss";
import { backendFetchIndustries } from "../../backend/industries";
import { dbIndustryToIndustrySelector } from "../../backend/helpers";

interface Option {
	value: string,
	label: string
}

export default function IndustrySelector({ onChange = () => { } }: any) {
	const [selectedOption, setSelectedOption] = useState(null);
	const [options, setOptions] = useState([] as Option[]);

	// fetch industries from backend for IndustrySelector
	useEffect(() => {
		backendFetchIndustries()
			.then(r => dbIndustryToIndustrySelector(r))
			.then(r => setOptions(r));
	}, []);

	const handleChange = (selectedOption: any) => {
		setSelectedOption(selectedOption);
		onChange(selectedOption.value);
	};

	return (
		<div className={s.root}>
			<div className={s.title}>What Industry are you applying to?</div>

			<Select
				className={s.selector}
				classNamePrefix="csia-select"
				options={options}
				value={selectedOption}
				onChange={handleChange}
			/>
		</div>
	);
}

import React from "react";
import { Routes, Route, Navigate } from "react-router";

import "./assets/styles/app.scss";

import AppWrapper from "./pages/app-wrapper/AppWrapper";

import LoginPage from "./pages/login/LoginPage";
import RegisterVerifyEmailPage from "./pages/register/verify/RegisterVerifyEmailPage";
import RegisterPage from "./pages/register/RegisterPage";
import InviteForm from "./pages/boldi_invite_form/InviteForm";
import RegisterDetailsPage from "./pages/register/details/RegisterDetailsPage";
import RegisterConnectPage from "./pages/register/connect/RegisterConnectPage";
import RegisterApprovalPage from "./pages/register/approval/RegisterApprovalPage";
import { isAuthenticated } from "./helpers/AuthService";
import ApprovePage from "./pages/boldi_approve_page/ApprovePage";

const PrivateRoute = ({ children }) => {
	return isAuthenticated() ? <>{children}</> : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
	return isAuthenticated() ? <Navigate to={"/"} /> : <>{children}</>;
};

const CsiaApp = () => {
	return (
		<Routes>
			<Route
				exact
				path={"/login"}
				element={
					<PublicRoute>
						<LoginPage />
					</PublicRoute>
				}
			/>

			<Route path="/register" element={<RegisterPage />}>
				<Route path="magic?/:token?" element={<RegisterDetailsPage />} />
				<Route path="verify" element={<RegisterVerifyEmailPage />} />
				<Route path="connect" element={<RegisterConnectPage />} />
				<Route path="approval" element={<RegisterApprovalPage />} />
			</Route>

			<Route path="/invite" element={<InviteForm />} />
			<Route path="/approve" element={<ApprovePage />} />

			<Route
				path="*"
				element={
					<PrivateRoute>
						<AppWrapper />
					</PrivateRoute>
				}
			/>
		</Routes>
	);
};

export default CsiaApp;

import React, { useRef, useState } from "react";
import { uniqueId } from "lodash";
import { useDropzone } from "react-dropzone";

import s from "./UploadFilesWidget.module.scss";

export default function UploadFilesWidget({
	documents = [],
	readonly = false,
	onUploadComplete,
}) {
	const [selectedFiles, setSelectedFiles] = useState(documents);
	let logoFieldRef = useRef();

	// ------------------------------------- METHODS -------------------------------------
	const getFileContentPromise = (file) => {
		const reader = new FileReader();
		return new Promise((resolve) => {
			reader.onload = (ev) => {
				resolve({ data: ev.target.result, filename: file.name });
			};
			reader.readAsDataURL(file);
		});
	};

	const onDrop = async (uploadFiles) => {
		const promises = uploadFiles.map((file) => getFileContentPromise(file));
		const files = await Promise.all(promises);

		// Keep previous files & add new ones
		const newfiles = [...selectedFiles, ...files];
		setSelectedFiles(newfiles);

		onUploadComplete(
			newfiles.map((f) => ({
				...f,
				id: "file_" + Date.now(),
			}))
		);
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		minSize: 0,
		//accept: "image/png",
	});

	return (
		<div className={s.root}>
			{readonly ? null : (
				<div className={s.uploadContainer}>
					<div {...getRootProps()}>
						<div className={s.uploadBorder}>
							<div
								className={s.uploadZone}
								onClick={(e) => {
									if (
										!window?.navigator?.platform ||
										!window?.navigator?.platform.toLowerCase().includes("win")
									) {
										e.stopPropagation()
									}
									logoFieldRef.click();
								}}
							>
								<input
									hidden
									{...getInputProps()}
									ref={(elem) => (logoFieldRef = elem)}
									type="file"
								/>
								{/* <div className={s.icons}>
									<Icon icon={isDragActive ? "plus" : "upload"} />
								</div> */}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

import React from "react";
import cx from "classnames";

import s from "./RegisterStepsWidget.module.scss";
import DetailsIcon from "./icons/DetailsIcon";
import SubmitIcon from "./icons/SubmitIcon";
import VerifyIcon from "./icons/VerifyIcon";
import WalletIcon from "./icons/WalletIcon";

const Steps = [
	{ name: "details", title: "Your details", icon: DetailsIcon },
	{ name: "verify", title: "Verify your email", icon: VerifyIcon },
	{ name: "connect", title: "Connect your wallet", icon: WalletIcon },
	{ name: "approval", title: "Submit for approval", icon: SubmitIcon },
];

export default function RegisterStepsWidget({ step = "details" }) {
	return (
		<div className={s.root}>
			<ul className={s.steps}>
				{Steps.map(({ name, title, icon: IconTag }, index) => (
					<li
						key={index}
						className={cx(
							s.step,
							{ [s.active]: name === step },
							{ [s.fill]: name === "connect" }
						)}
					>
						<IconTag />
						<span>{title}</span>
					</li>
				))}
			</ul>
		</div>
	);
}

import { createContext, useContext, useEffect, useState } from "react";
import { binary_to_base58 } from "base58-js";

// ----------------------------------------------------------------------
export const WalletServiceContext = createContext({});

export const WalletServiceProvider = (props) => {
	const [provider, setProvider] = useState(undefined);
	const [isConnected, setIsConnected] = useState(false);
	const [walletKey, setWalletKey] = useState(null);

	// Detect phantom provider exists
	useEffect(() => {
		if ("solana" in window) {
			const { solana } = window;

			if (solana?.isPhantom) {
				setProvider(window.solana);

				// Attemp an eager connection
				try {
					solana
						.connect({ onlyIfTrusted: true })
						.then((response) => {
							console.log("Connected key=", response.publicKey?.toString());
							setWalletKey(response.publicKey?.toString());
						})
						.catch((error) => {
							console.log(error);
						});
					solana.on("accountChanged", (publicKey) => {
						if (publicKey) {
							// Set new public key and continue as usual
							console.log(`Switched to account ${publicKey.toBase58()}`);
							setWalletKey(publicKey.toString());
						}
					});
				} catch (error) { }
			}
		}
	}, []);

	// WALLET SUBSCRIPTIONS
	useEffect(() => {
		provider?.on("connect", (wk) => {
			setIsConnected(true);
			setWalletKey(wk);
		});

		provider?.on("disconnect", () => {
			setWalletKey(null);
			setIsConnected(false);
		});
	}, [provider]);

	// ----------------------------------------------------------------------

	const connectWallet = async () => {
		console.log("****");
		const { solana } = window;

		if (!solana) {
			throw new Error("Phantom extension missing");
		}

		try {
			const response = await solana.connect();
			console.log("wallet account ", response.publicKey.toString());
			setWalletKey(response.publicKey.toString());
			setIsConnected(true);
			return response.publicKey.toString();
		} catch (err) {
			console.debug(err);
			window.alert(err);
			throw err;
		}
	};

	const signMessage = async (msg) => {
		const { solana } = window;
		if (!solana) {
			return null;
		}

		const encodedMessage = new TextEncoder().encode(msg);
		const signedMessage = await solana.signMessage(encodedMessage, "utf8");
		return binary_to_base58(signedMessage.signature)
	}

	const disconnectWallet = async () => {
		const { solana } = window;

		if (walletKey && solana) {
			await solana.disconnect();
			setIsConnected(false);
			setWalletKey(null);
		}
	};

	const getUmiPhantomSigner = async () => {
		const { solana } = window;
		const signMessage = solana.signMessage;
		const signTransaction = solana.signTransaction;
		const signAllTransactions = solana.signAllTransactions;
		return { publicKey: walletKey, signMessage, signTransaction, signAllTransactions };
	}

	const installPhantom = () => {
		window.open("https://phantom.app/learn/guides/how-to-create-a-new-wallet");
	}

	/* 	const isWalletConnected = async () => {
		try {
			const { solana } = window;
			if (solana) {
				if (solana.isPhantom) {
					console.debug("phantom wallet found");

					// If user already connected, { onlyIfTrusted: true }
					const response = await solana.connect({ onlyIfTrusted: false });
					console.log("public key=", response.publicKey.toString());
					setWalletKey(response.publicKey.toString());
				} else {
					console.log("Please install phantom wallet");
				}
			} else {
				console.log("Please install phantom wallet");
			}
		} catch (error) {
			console.log(error);
		}
	}; */

	// ********************************* BUSINESS UTILITY METHODS *********************************
	const contextProps = {
		provider,
		walletKey,
		isConnected,
		getUmiPhantomSigner,
		connectWallet,
		disconnectWallet,
		signMessage,
		installPhantom,
	};

	return <WalletServiceContext.Provider value={contextProps} {...props} />;
};

export const useWalletService = () => {
	return useContext(WalletServiceContext);
};

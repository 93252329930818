const login = (sessionV0) => {
	return new Promise((resolve) => {
		localStorage.setItem("token", JSON.stringify(sessionV0));
		setIsLoggedIn("true");
		resolve(true);
	});
};

const logout = () => {
	return new Promise((resolve) => {
		localStorage.setItem("isLoggedIn", "false");
		resolve(true);
	});
};

const setIsLoggedIn = (val) => {
	localStorage.setItem("isLoggedIn", val);
}

const getSessionV0Token = () => {
	return localStorage.getItem("token");
}

const cleanSessionV0Token = () => {
	localStorage.removeItem("token");
}

const getCurrentUser = () => {
	return JSON.parse(localStorage.getItem("token"));
};

export const isAuthenticated = () => {
	return "true" === localStorage.getItem("isLoggedIn");
};

const AuthService = {
	login,
	logout,
	getSessionV0Token,
	cleanSessionV0Token,
	setIsLoggedIn,
	getCurrentUser,
	isAuthenticated
};

export default AuthService;

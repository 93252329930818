import React from "react";

export default function WalletIcon({ width = 32, height = 28 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M28.7731 4.96065H4.56144C4.24038 4.96065 3.93246 4.82975 3.70543 4.59674C3.4784 4.36374 3.35086 4.04772 3.35086 3.7182C3.35086 3.38869 3.4784 3.07267 3.70543 2.83966C3.93246 2.60666 4.24038 2.47576 4.56144 2.47576H25.1414C25.4625 2.47576 25.7704 2.34486 25.9974 2.11186C26.2244 1.87885 26.352 1.56283 26.352 1.23332C26.352 0.903802 26.2244 0.587782 25.9974 0.354779C25.7704 0.121775 25.4625 -0.00912476 25.1414 -0.00912476H4.56144C3.59824 -0.00912476 2.67449 0.383575 1.9934 1.08258C1.31232 1.78159 0.929688 2.72965 0.929688 3.7182V23.5973C0.929688 24.5858 1.31232 25.5339 1.9934 26.2329C2.67449 26.9319 3.59824 27.3246 4.56144 27.3246H28.7731C29.4153 27.3246 30.0311 27.0628 30.4852 26.5968C30.9392 26.1308 31.1943 25.4988 31.1943 24.8397V7.44553C31.1943 6.7865 30.9392 6.15446 30.4852 5.68845C30.0311 5.22245 29.4153 4.96065 28.7731 4.96065ZM28.7731 24.8397H4.56144C4.24038 24.8397 3.93246 24.7088 3.70543 24.4758C3.4784 24.2428 3.35086 23.9268 3.35086 23.5973V7.23276C3.73954 7.3743 4.14902 7.44627 4.56144 7.44553H28.7731V24.8397ZM21.5096 15.5214C21.5096 15.1528 21.6161 14.7925 21.8157 14.486C22.0152 14.1795 22.2988 13.9407 22.6306 13.7996C22.9624 13.6586 23.3275 13.6216 23.6798 13.6936C24.032 13.7655 24.3556 13.943 24.6095 14.2036C24.8635 14.4642 25.0364 14.7963 25.1065 15.1578C25.1766 15.5193 25.1406 15.8941 25.0032 16.2346C24.8657 16.5751 24.633 16.8662 24.3344 17.071C24.0357 17.2758 23.6847 17.3851 23.3255 17.3851C22.8439 17.3851 22.382 17.1887 22.0415 16.8392C21.701 16.4897 21.5096 16.0157 21.5096 15.5214Z"
				fill="#9DA2B3"
			/>
		</svg>
	);
}

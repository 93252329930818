import React from "react";
import cx from "classnames";
import s from "./Sidebar.module.scss";

import AppRoutes from "./AppRoutes";

import Logo from "../logo/Logo";
import SidebarLink from "./SidebarLink/SidebarLink";

export default function Sidebar() {
	const [sidebarOpen] = React.useState(false);

	return (
		<nav
			className={cx(s.root, { [s.sidebarOpen]: sidebarOpen })}
			//onMouseEnter={() => setSidebarOpen(true)}
			//onMouseLeave={() => setSidebarOpen(false)}
		>
			<header className={s.logoContainer}>
				<Logo className={s.logo} />
			</header>

			<div className={s.nav}>
				{AppRoutes.map((r, index) => (
					<SidebarLink key={index} {...r} />
				))}
			</div>
		</nav>
	);
}

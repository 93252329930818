import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import s from "./LoginPage.module.scss";

import ImageGradientWidget from "../../components/image-gradient-widget/ImageGradientWidget";
import AppButton from "../../components/app-button/AppButton";
import Logo from "../../components/logo/Logo";
import { useWalletService } from "../../helpers/WalletService";
import { backendCheckMembershipNFT } from "../../backend/login";
import AuthService from "../../helpers/AuthService";
import { backendFetchProfile } from "../../backend/sessiondata";

export default function LoginPage() {
	const { installPhantom, provider, connectWallet, signMessage } = useWalletService();
	const navigate = useNavigate();

	const connectPhantomWallet = async () => {
		const _publicKey = await connectWallet();

		// if there's a sessionV0 in local storage => backendFetchProfile() with it and return
		const localStorageSessionV0 = AuthService.getSessionV0Token();
		if (localStorageSessionV0) {
			const localStorageSessionV0PublicKey = JSON.parse(localStorageSessionV0).walletKey;
			if (localStorageSessionV0PublicKey === _publicKey) {
				const profile = await backendFetchProfile(localStorageSessionV0);
				if (profile.res === "err") {
					AuthService.cleanSessionV0Token();
				} else {
					AuthService.setIsLoggedIn("true");
					navigate("/");
					return;
				}
			} else {
				AuthService.cleanSessionV0Token();
			}
		}

		backendCheckMembershipNFT(_publicKey, signMessage)
			.then(r => {
				if (r.res === "err") { // TODO: what if user has no membership NFT?
					window.alert("Error while checking membership NFT: " + JSON.stringify(r, null, 2));
					return;
				}
				const sessionV0 = JSON.parse(r.data);
				if (!sessionV0 || r.data.includes("csia_role_none")) {
					window.alert("Login denied, no roles associated with wallet");
					return;
				}
				AuthService.login(sessionV0)
					.then(() => backendFetchProfile(r.data))
					.then(r => {
						if (r.res === "err") {
							window.alert("Failed to fetch profile data from backend: " + JSON.stringify(r, null, 2));
							AuthService.logout()
								.then(() => navigate("/login"));
							return;
						}
						navigate("/");
					});
			})
	};

	return (
		<div className={s.root}>
			<ImageGradientWidget>
				<Logo className={s.logo} />
			</ImageGradientWidget>

			<div className={s.container}>
				<p className={s.heading}>
					Credential Standards Institute Association
				</p>
				<p className={s.loginText}>Login to CSIA</p>
				<p className={s.note}>
					Logging into CSIA requires an active Phantom wallet
				</p>

				{!provider
					? (<AppButton
						text="Install Phantom Wallet extension"
						className={s.button}
						onClick={installPhantom}
					/>)
					: (<AppButton
						text="Connect with Phantom"
						className={s.button}
						onClick={() => connectPhantomWallet()}
					/>)}

				{/* {!provider || !walletKey ? (
					<AppButton
						text="Connect with Phantom"
						className={s.button}
						onClick={() => connectWallet()}
					/>
				) : null} */}

				{/* {provider && walletKey ? (
					<AppButton text="Connected" className={cx(s.button, s.connected)} />
				) : null} */}

				<p className={s.noAccount}>
					Don't have an account?
					<Link to={"/register"}>Sign up</Link>
				</p>
			</div>
		</div>
	);
}

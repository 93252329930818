import React from "react";

import { Card, CardTitle, CardBody } from "reactstrap";
import cx from "classnames";

import s from "./DashboardHome.module.scss";

import TabsWidget from "../../tabs-widget/TabsWidget";
import AppButton from "../../app-button/AppButton";
import InfoIcon from "../../icons/InfoIcon";
import AddIcon from "../../icons/AddIcon";

export default function DashboardHome() {
	const tabs = [
		{ name: "current", title: "CURRENT" },
		{ name: "past", title: "PAST" },
	];

	const [activeTab, setActiveTab] = React.useState(tabs[0].name);

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	return (
		<div className={s.root}>
			<Card className={s.card}>
				<CardBody>
					<CardTitle className={s.title}>Voting</CardTitle>

					<AppButton
						Icon={AddIcon}
						text="New Proposal"
						className={cx(s.button, "btn-primary")}
					/>

					<div className={s.icon}>
						<InfoIcon />
					</div>

					<TabsWidget
						tabs={tabs}
						activeTab={activeTab}
						handleTabChange={handleTabChange}
					>
						{activeTab === tabs[0].name ? <div>CURRENT</div> : null}
						{activeTab === tabs[1].name ? <div>PAST</div> : null}
					</TabsWidget>
				</CardBody>
			</Card>
		</div>
	);
}

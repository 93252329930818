import { DocumentModel, UserModel } from "../models/AppModels";
import { ApplicationModel } from "../pages/boldi_approve_page/ApprovePage";
import {
	getNodeApi,
	ORGANIZATION,
	PLUGIN_NAME,
	pub,
	SCOPE,
	useBackend,
} from "./common";

const CREATE_NEW_APPLICATION_REQUEST = "createApplication";
const SEND_EMAIL_VALIDATION_CODE = "sendEmailValidationCode";
const VERIFY_EMAIL_CODE = "verifyEmailCode";
const ADD_WALLET_KEY_TO_APPLICATION = "addWalletKeyToApplication";
const FETCH_WAITING_APPLICATIONS = "fetchWaitingApplications";
const TREAT_APPLICATION = "treatApplication";

export const backenCreateApplication = async (userModel: UserModel, documents: DocumentModel[], token: String) => {
	if (!useBackend)
		return new Promise((resolve) => {
			resolve({ res: "ok", data: '{"validateEmail":true}' });
		});

	const nodeApi = getNodeApi();

	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

	const args = { ...userModel, documents, token };
	return await nodeApi.pluginCall(
		session,
		PLUGIN_NAME,
		CREATE_NEW_APPLICATION_REQUEST,
		args,
		null
	);
};

export const backendSendVerificationMail = async (email: string) => {
	if (!useBackend)
		return new Promise((resolve) => {
			resolve({ res: "ok" });
		});

	const nodeApi = getNodeApi();
	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

	const args = { email: email };
	return await nodeApi.pluginCall(
		session,
		PLUGIN_NAME,
		SEND_EMAIL_VALIDATION_CODE,
		args,
		null
	);
};

export const backendValidateEmailVerificationCode = async (
	email: string,
	code: string
) => {
	if (!useBackend)
		return new Promise((resolve) => {
			resolve({ res: "ok", data: '{"match":true}' });
		});

	const nodeApi = getNodeApi();
	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");

	const args = { email: email, code: code };
	return await nodeApi.pluginCall(
		session,
		PLUGIN_NAME,
		VERIFY_EMAIL_CODE,
		args,
		null
	);
};

export const backendAddWalletToApplication = async (email: string, walletKey: string) => {
	if (!useBackend)
		return new Promise((resolve) => {
			resolve({ res: "ok", data: 'success' });
		});

	const nodeApi = getNodeApi();
	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");
	const args = { email: email, wallet_key: walletKey };
	return await nodeApi.pluginCall(
		session,
		PLUGIN_NAME,
		ADD_WALLET_KEY_TO_APPLICATION,
		args,
		null);
}

export const backendFetchWaitingApplications = async () => {
	if (!useBackend)
		return new Promise((resolve) => {
			resolve({ res: "ok", data: '[]' });
		});

	const nodeApi = getNodeApi();
	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");
	return await nodeApi.pluginCall(
		session,
		PLUGIN_NAME,
		FETCH_WAITING_APPLICATIONS,
		{},
		null);
}

export const backendApproveApplication = async (frontendApplication: ApplicationModel) => {
	if (!useBackend)
		return new Promise((resolve) => {
			resolve({ res: "ok", data: 'success' });
		});

	const nodeApi = getNodeApi();
	const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");
	const args = { ...frontendApplication };
	return await nodeApi.pluginCall(
		session,
		PLUGIN_NAME,
		TREAT_APPLICATION,
		args,
		null);
}
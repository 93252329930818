import React from "react";
import cx from "classnames";

import s from "./UploadFilesButton.module.scss";

import UploadFilesWidget from "../UploadFilesWidget";
import { UploadFilesProps } from "../../../models/AppModels";
import SelectedDocuments from "../SelectedDocuments/SelectedDocuments";

export default function UploadFilesButton({
	documents,
	className = "",
	isMandatory = false,
	title = "Upload Business Formation Document(s)",
	onUploadComplete,
}: UploadFilesProps) {
	return (
		<div className={cx(s.root, className)}>
			<div className={s.title}>
				{title}
				{isMandatory && <span className="mandatory">*</span>}
			</div>

			<div className={s.content}>
				<SelectedDocuments documents={documents} />

				<div className={s.uploadContainer}>
					<div className={s.uploadIcon}>+</div>

					<div className={s.dropzone}>
						<UploadFilesWidget
							documents={documents}
							onUploadComplete={onUploadComplete}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

import PersonalIcon from "./PersonalIcon";
import SettingsIcon from "./SettingsIcon";
import DashboardIcon from "./DashboardIcon";
import DirectoryIcon from "./DirectoryIcon";
import LogoutIcon from "./LogoutIcon";
import UserProfileIcon from "./UserProfileIcon";
import IndustryIcon from "./IndustryIcon";

const SidebarIcons = {
	PersonalIcon,
	DirectoryIcon,
	DashboardIcon,
	SettingsIcon,
	LogoutIcon,
	IndustryIcon,
	UserProfileIcon
};

export default SidebarIcons;

import s from "./RegisterApprovalPage.module.scss";

import AppButton from "../../../components/app-button/AppButton";

export default function RegisterApprovalPage() {

	const onNext = () => {
		window.open("https://standardsinstitute.org", "_self");
	};

	return (
		<div className={s.root}>
			<div className={s.title}>
				Your application to join our{" "}
				<span>community is now being reviewed</span>
			</div>
			<div className={s.note}>
				Please allow 24-48 hours to review your application.
				If approved, you will be awarded an NFT and will be granted access to your User Profile.
			</div>

			<div className={s.note}>
				We'll send you an email when our review is completed.
			</div>

			<AppButton
				text="Return Home"
				className={s.button}
				onClick={() => onNext()}
			/>
		</div>
	);
}

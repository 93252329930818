import React from "react";
import { Outlet } from "react-router";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import s from "./RegisterPage.module.scss";
import ImageGradientWidget from "../../components/image-gradient-widget/ImageGradientWidget";
import RegisterStepsWidget from "../../components/register-steps-widget/RegisterStepsWidget";

/**
 * TODO: access this from Magic Link also
 * .../register/details/<encoded prefill data>
 * 
 * TODO: how to get encoded pre-fill data? useParams() vs useLocation() ??
 */
export default function RegisterPage() {
	const location = useLocation();
	// custom logic to decode base58
	const currentPath = location.pathname;

	const subpath = currentPath.split("/").slice(2);
	const step = subpath.length === 0 ? "details" : subpath[0];

	return (
		<div className={s.root}>
			<div className={s.gradient}>
				<ImageGradientWidget>
					<div className={s.leftContainer}>
						<h1 className={s.heading}>
							Credential Standards Institute Association
						</h1>
						<h2 className={s.subHeading}>
							Sign up to join the CSIA community{" "}
						</h2>

						<RegisterStepsWidget step={step} />

						<div className={s.navBar}>
							<Link to="/">Return to Home</Link>
							<Link to="/login">Sign in</Link>
						</div>
					</div>
				</ImageGradientWidget>
			</div>

			<Outlet />
		</div>
	);
}

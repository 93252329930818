import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cx from "classnames";

import s from "./RegisterConnectPage.module.scss";

import AppButton from "../../../components/app-button/AppButton";
import { useWalletService } from "../../../helpers/WalletService";
import { WalletServiceResponse } from "../../../models/AppModels";
import { backendAddWalletToApplication } from "../../../backend/applications";

export default function RegisterConnectPage() {
	const navigate = useNavigate();

	const location = useLocation();
	const userModel = location.state.userModel;

	const { installPhantom, provider, connectWallet, walletKey } =
		useWalletService() as WalletServiceResponse;

	// Methods
	const onNext = () => {
		if (!walletKey) return;

		// TODO: send to backend the connected public key
		backendAddWalletToApplication(userModel.email, walletKey)
			.then(r => {
				if (r.res !== "ok") {
					console.error("Error while adding walletKey to application data: " + JSON.stringify(r));
					return; // TODO: error handling?
				}
				if (r.data === "success") {
					navigate("../approval")
				}
			});
	};

	const connect = () => {
		connectWallet()
			.catch(e => {
				window.alert(e)
			});
	};

	return (
		<div className={s.root}>
			<div className={s.title}>Connect your wallet</div>

			{/* if Phantom extension missing */}
			{!provider
				? (<AppButton
					text="Install Phantom Wallet extension"
					className={cx(s.connectButton, "btn-border")}
					onClick={installPhantom}
				/>)
				: (!walletKey ? (
					<AppButton
						text="Connect Phantom Wallet"
						className={cx(s.connectButton, "btn-border")}
						onClick={connect}
					/>
				) : (<AppButton text={"Connected wallet: " + walletKey} className={cx(s.connected)} />))
			}
			<AppButton text="Next" className={s.button} onClick={() => onNext()} />
		</div>
	);
}

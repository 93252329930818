import { getNodeApi, ORGANIZATION, pub, READ_DEFAULT_NO_CHAIN, SCOPE, useBackend } from "./common";

const table_industries = "industries";

export const backendFetchIndustries = async () => {

    if (!useBackend) { // TODO: remove once we have customer environment
        return [
            { id: 0, name: "Rolex Reselling" },
            { id: 1, name: "Luxury Pencil Sharpeners" },
            { id: 2, name: "Birkin Bags" },
        ];
    }

    const nodeApi = getNodeApi();
    const session = await nodeApi.login(ORGANIZATION, pub, SCOPE || "*");
    const result = await nodeApi.read(session, SCOPE, table_industries, null, READ_DEFAULT_NO_CHAIN);

    if (result.res !== "ok") {
        console.error("Error while fetching industries: " + JSON.stringify(result));
        return [];
    }

    return result.data;
}
import React from "react";
import { Button } from "reactstrap";
import cx from "classnames";

import s from "./AppButton.module.scss";
import { AppButtonProps } from "../../models/AppModels";

export default function AppButton({
	text,
	className = "primary",
	onClick,
	Icon,
}: AppButtonProps) {
	return (
		<Button
			className={cx(s.root, "btn-default", className)}
			color="primary"
			onClick={onClick}
		>
			{Icon ? (
				<span className={s.icon}>
					<Icon />
				</span>
			) : null}
			{text}
		</Button>
	);
}

import Icons from "./icons";

const appRoutes = [
	{
		//url: "/industry",
		// name: "Fine Art and Collectibles",
		// hasNotification: true,
		// icon: Icons.IndustryIcon,
	},
	{
		url: "/",
		name: "Profile",
		//onlyIcon: true,
		icon: Icons.UserProfileIcon,
	},
	{
		name: "Logout",
		url: "/login",
		onlyIcon: true,
		icon: Icons.LogoutIcon,
	},
	/* {
    url: "/directory",
    name: "Directory",
    isPositions: true,
    icon: Icons.DirectoryIcon,
  },
  {
    name: "Dashboard",
    url: "/dashboard",
    icon: Icons.DashboardIcon,
  },
  {
    url: "/settings",
    name: "Settings",
    icon: Icons.SettingsIcon,
  }, */
];

export default appRoutes;


import { useEffect, useState } from "react";
import s from "./ApprovePage.module.scss";
import { backendApproveApplication, backendFetchWaitingApplications } from "../../backend/applications";
import { dbApplicationToFrontendApplication } from "../../backend/helpers";
import { useWalletService } from "../../helpers/WalletService";
import { WalletServiceResponse } from "../../models/AppModels";
import AppButton from "../../components/app-button/AppButton";


export interface ApplicationModel {
    "id": string,
    "ts": string,
    "isColdSignUp": string,
    "emailVerified": string,
    "status": string,
    "industryId": string,
    "name": string,
    "email": string,
    "walletKey": string,
    "website": string
}

export default function ApprovePage() {

    const [applications, setApplications] = useState([] as ApplicationModel[]);
    const { getUmiPhantomSigner } = useWalletService() as WalletServiceResponse;

    const buildApplicationsFromBackendResponse = (backendApplications: []) => {

    }

    // load all pending applications pending approval
    useEffect(() => {
        backendFetchWaitingApplications()
            .then(r => {
                let _applications = dbApplicationToFrontendApplication(r.data);
                setApplications(_applications);
            });
    }, []);

    const approve = async (i: number) => {

        backendApproveApplication(applications[i])
            .then(r => {
                if (r.res === "err") {
                    window.alert("Failed to mint membership-NFT to " + applications[i].walletKey + "\nReason: " + JSON.stringify(r));
                    return;
                }
                const wasMinted = JSON.parse(r.data).result;
                if (wasMinted !== "true") {
                    window.alert("Failed to mint membershipt-NFT to " + applications[i].walletKey);
                    return;
                }
                window.alert("Successfully minted NFT to " + applications[i].walletKey);
            });
    }

    return (
        <div>
            Number of waiting applications: {applications.length}

            <div className={s.root}>
                <table className={s.styledtable}>
                    <thead>
                        <tr>
                            <td>id</td>
                            <td>timestamp</td>
                            <td>isColdSignUp</td>
                            <td>emailVerified</td>
                            <td>status</td>
                            <td>industryId</td>
                            <td>name</td>
                            <td>email</td>
                            <td>walletKey</td>
                            <td>website</td>
                            <td>action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {applications.map((a, i) =>
                            <tr>
                                <td>{a.id}</td>
                                <td>{a.ts}</td>
                                <td>{a.isColdSignUp}</td>
                                <td>{a.emailVerified}</td>
                                <td>{a.status}</td>
                                <td>{a.industryId}</td>
                                <td>{a.name}</td>
                                <td>{a.email}</td>
                                <td>{a.walletKey}</td>
                                <td>{a.website}</td>
                                <td><button onClick={() => approve(i)}>Approve</button></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
import React from "react";

export default function SettingsIcon({ width = 45, height = 45 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3437 41.2499L16.5937 35.2499C16.1875 35.0936 15.8047 34.9061 15.4453 34.6874C15.0859 34.4686 14.7344 34.2343 14.3906 33.9843L8.8125 36.328L3.65625 27.4218L8.48437 23.7655C8.45312 23.5468 8.4375 23.3358 8.4375 23.1327V21.8671C8.4375 21.6639 8.45312 21.453 8.48437 21.2343L3.65625 17.578L8.8125 8.67175L14.3906 11.0155C14.7344 10.7655 15.0937 10.5311 15.4687 10.3124C15.8437 10.0936 16.2187 9.90613 16.5937 9.74988L17.3437 3.74988H27.6562L28.4062 9.74988C28.8125 9.90613 29.1953 10.0936 29.5547 10.3124C29.9141 10.5311 30.2656 10.7655 30.6094 11.0155L36.1875 8.67175L41.3437 17.578L36.5156 21.2343C36.5469 21.453 36.5625 21.6639 36.5625 21.8671V23.1327C36.5625 23.3358 36.5312 23.5468 36.4687 23.7655L41.2969 27.4218L36.1406 36.328L30.6094 33.9843C30.2656 34.2343 29.9062 34.4686 29.5312 34.6874C29.1562 34.9061 28.7812 35.0936 28.4062 35.2499L27.6562 41.2499H17.3437ZM22.5937 29.0624C24.4062 29.0624 25.9531 28.4218 27.2344 27.1405C28.5156 25.8593 29.1562 24.3124 29.1562 22.4999C29.1562 20.6874 28.5156 19.1405 27.2344 17.8593C25.9531 16.578 24.4062 15.9374 22.5937 15.9374C20.75 15.9374 19.1953 16.578 17.9297 17.8593C16.6641 19.1405 16.0312 20.6874 16.0312 22.4999C16.0312 24.3124 16.6641 25.8593 17.9297 27.1405C19.1953 28.4218 20.75 29.0624 22.5937 29.0624Z"
        fill="#D3D6E0"
      />
    </svg>
  );
}

import React from "react";

export default function DashboardIcon({ width = 45, height = 45 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 42.5403V3.7301"
        stroke="#BCBFCC"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M22.5 16.4651H41.9093"
        stroke="#BCBFCC"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M3.0918 25.0685H22.5011"
        stroke="#BCBFCC"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M7.41677 3.09497H37.6434C40.0541 3.09497 41.9703 5.01382 41.9703 7.42785V37.6342C41.9703 39.9863 40.0541 41.967 37.6434 41.967H7.41677C5.00605 41.967 3.08984 40.0482 3.08984 37.6342V7.42785C3.08984 5.07571 5.00605 3.09497 7.41677 3.09497Z"
        stroke="#BCBFCC"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

import React from "react";
import { Routes, Route } from "react-router";

import s from "./AppWrapper.module.scss";

import Sidebar from "../../components/sidebar/Sidebar";
import DirectoryPage from "../directory/DirectoryPage";
import ProfilePage from "../profile/ProfilePage";
import DashboardPage from "../dashboard/DashboardPage";

const AppWrapper = () => {
	return (
		<div className={s.root}>
			<Sidebar />
			<div className={s.main}>
				<div className={s.content}>
					<Routes>
						<Route path="/directory" exact element={<DirectoryPage />} />
						<Route path="/dashboard" exact element={<DashboardPage />} />
						<Route path="/" exact element={<ProfilePage />} />
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default AppWrapper;

import React from "react";

export default function SubmitIcon({ width = 35, height = 36 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 35 35"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="17.6633"
				cy="17.841"
				r="16.3933"
				stroke="#9DA2B3"
				strokeWidth="1.72561"
			/>
			<path
				d="M12.9173 22.5866L22.4082 13.0957M22.4082 13.0957V22.5866M22.4082 13.0957H12.9173"
				stroke="#9DA2B3"
				strokeWidth="1.89818"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import s from "./Logo.module.scss";

import AppLogo from "../../assets/images/app-logo.svg";

export default function Logo({ className }) {
  return (
    <Link to={"/"} className={cx(s.root, className)}>
      <img src={AppLogo} alt="..." />
    </Link>
  );
}

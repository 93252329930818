import React from "react";
import cx from "classnames";

import s from "./Notification.module.scss";

export default function Notification({
	hasNotification = false,
	className = "",
}) {
	return hasNotification ? <div className={cx(s.root, className)}></div> : null;
}

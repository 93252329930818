import React from "react";

export default function DetailsIcon({ width = 37, height = 37 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 37 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M29.9793 29.5429C27.0315 27.4424 21.7674 26.3665 18.6089 26.3665C15.4504 26.3665 10.2916 27.3912 7.34375 29.4917M18.6615 19.3476C21.6621 19.3476 24.0836 16.9909 24.0836 14.0706C24.0836 11.1504 21.6621 8.79364 18.6615 8.79364C15.661 8.79364 13.2395 11.1504 13.2395 14.0706C13.2395 16.9909 15.661 19.3476 18.6615 19.3476Z"
				stroke="#9DA2B3"
				strokeWidth="2.01205"
				strokeMiterlimit="10"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.6625 2.19165C27.5015 2.19165 34.696 9.38619 34.696 18.2252C34.696 27.0642 27.5015 34.2587 18.6625 34.2587C9.82345 34.2587 2.62891 27.0642 2.62891 18.2252C2.62891 9.38619 9.82345 2.19165 18.6625 2.19165Z"
				stroke="#9DA2B3"
				strokeWidth="2.01205"
				strokeMiterlimit="10"
			/>
		</svg>
	);
}

import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";

import s from "./RegisterVerifyEmailPage.module.scss";

import AppButton from "../../../components/app-button/AppButton";
import {
	backendSendVerificationMail,
	backendValidateEmailVerificationCode,
} from "../../../backend/applications";

export default function RegisterVerifyEmailPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const userModel = location.state.userModel;
	const [code, setCode] = useState("");

	// send out verification email on page load
	useLayoutEffect(() => {
		backendSendVerificationMail(userModel.email).then((r) => {
			if (r.res !== "ok") {
				console.error(
					"Error while sending verification email: " + JSON.stringify(r)
				);
				return; // TODO: error handling?
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Methods
	const onNext = () => {
		if (code) {
			backendValidateEmailVerificationCode(userModel.email, code).then((r) => {
				if (r.res !== "ok") {
					console.error(
						"Error while checking verification code: " + JSON.stringify(r)
					);
					return; // TODO: error handling?
				}
				const match = JSON.parse(r.data).match;
				if (!match || match === "false") {
					window.alert("HAHA, nice try guy!!");
					return;
				}
				navigate("../connect");
			});
		}
	};

	return (
		<div className={s.root}>
			<div className={s.title}>Check your email</div>
			<div className={s.note}>
				<span>
					A verification code has beend sent to the email you provided.
				</span>
				<span>Please provide the code below</span>
			</div>
			<div className={s.note}>This code will expire in 10 minutes.</div>

			<div className={s.inputContainer}>
				<InputMask
					mask="999-999"
					placeholder="000-000"
					value={code || ""}
					onChange={(e) => setCode(e.target.value)}
				/>
			</div>
			<div className={s.label}>Send another code</div>

			<AppButton text="Next" className={s.button} onClick={() => onNext()} />
		</div>
	);
}

import React from "react";

import s from "./DashboardHeader.module.scss";
import { UserModel } from "../../../models/AppModels";

export default function DashboardHeader({ data }: { data: UserModel }) {
	return (
		<div className={s.root}>
			<div className={s.info}>
				<div className={s.name}>{data.name}</div>
				{data.description ? (
					<div className={s.company}>{data.description}</div>
				) : null}
			</div>
		</div>
	);
}

import React from "react";

export default function RemoveIcon({ width = 12, height = 12 }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_791_14777)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.00261 5.31591L7.74582 3.57269C7.8614 3.45711 8.01817 3.39218 8.18163 3.39218C8.34508 3.39218 8.50185 3.45711 8.61743 3.57269C8.73301 3.68828 8.79795 3.84504 8.79795 4.0085C8.79795 4.17196 8.73301 4.32872 8.61743 4.4443L6.87421 6.18752L8.61743 7.93073C8.73301 8.04631 8.79795 8.20308 8.79795 8.36654C8.79795 8.52999 8.73301 8.68676 8.61743 8.80234C8.50185 8.91792 8.34508 8.98285 8.18163 8.98285C8.01817 8.98285 7.8614 8.91792 7.74582 8.80234L6.00261 7.05912L4.25939 8.80234C4.14381 8.91792 3.98705 8.98285 3.82359 8.98285C3.66013 8.98285 3.50337 8.91792 3.38779 8.80234C3.2722 8.68676 3.20727 8.52999 3.20727 8.36654C3.20727 8.20308 3.2722 8.04631 3.38779 7.93073L5.131 6.18752L3.38779 4.4443C3.2722 4.32872 3.20727 4.17196 3.20727 4.0085C3.20727 3.84504 3.2722 3.68828 3.38779 3.57269C3.50337 3.45711 3.66013 3.39218 3.82359 3.39218C3.98705 3.39218 4.14381 3.45711 4.25939 3.57269L6.00261 5.31591Z"
					fill="#D07567"
				/>
			</g>
			<defs>
				<clipPath id="clip0_791_14777">
					<rect width="12" height="12" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
